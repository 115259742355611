import AlertBanner from 'components/AlertBanner'
import React from 'react'
import { observer } from 'mobx-react-lite'
import FeedNavigation from 'components/FeedNavigation'
import GlobalStyles from 'components/GlobalStyles'
import HashtagLabsAd from 'components/HashtagLabsAd'
import NotificationContainer from 'components/Notification'
import SiteFooter from 'components/SiteFooter'
import SiteHeader from 'components/SiteHeader'
import SiteSubNavigation from 'components/SiteSubNavigation'
import SmartBanner from 'components/SmartBanner'
import { useStore } from 'hooks'
import { useHashtagLabs } from 'hooks/hashtag-labs'
import ConfigStore from 'stores/config'
import NavigationStore from 'stores/navigation'
import { getDeviceType, isServer, THEME } from 'utils'

const SiteLayout = ({
  children,
  background = '#F5F5F5',
  hideBanner = false,
  feedNavOptions = null,
  hideHeader = false,
  hideSiteSubNavigation = false,
  hideFooter = false,
  hideFooterConfigData = false,
  hideSmartBanner = false,
  adSettings = false,
  adFree = false
}) => {
  const configStore = useStore(ConfigStore)
  const navigationStore = useStore(NavigationStore)

  useHashtagLabs({ ...adSettings, adFree })

  const deviceType = getDeviceType()

  return (
    <>
      <div className='layout'>
        {!isServer && deviceType === 'ios' && !hideSmartBanner && <SmartBanner deviceType={deviceType} />}
        {/* BFCM Popup */}
        {/* {!adFree && adSettings && <div className='htlad-interstitial' data-unit='Barstool-Sports-Web/interstitial' />} */}
        {!hideBanner && configStore?.current?.alert_banner?.enabled && <AlertBanner />}
        {!hideHeader && <SiteHeader />}
        <div className='layout__overlayContainer' onClick={() => navigationStore.close()}>
          <div className='layout__overlay' />
          {!hideSiteSubNavigation && <SiteSubNavigation />}
          {feedNavOptions && (
            <div className='feedNavBackdrop'>
              <FeedNavigation navOptions={feedNavOptions} />
            </div>
          )}
          {!adFree && adSettings && (
            <div className='ad ad--leaderboard'>
              <HashtagLabsAd id='leaderboard-top' adSettings={adSettings} />
            </div>
          )}
          <main className='layout__main'>{children}</main>
          {!hideFooter && <SiteFooter footerData={!hideFooterConfigData ? configStore?.current?.footer : null} />}
          {!adFree && adSettings && (
            <div>
              <div className='ad ad--fixedBottom'>
                <HashtagLabsAd id='fixed-bottom' adSettings={adSettings} />
              </div>
            </div>
          )}
        </div>
        <NotificationContainer />
        <GlobalStyles />
      </div>
      <style jsx>{`
        .layout {
          background: ${background};
        }
        .layout__overlayContainer {
          position: relative;
        }
        .layout__overlay {
          display: none;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 10;
          background: rgba(0, 0, 0, 0.5);
        }
        .ad--leaderboard {
          margin-top: 8px;
          margin-bottom: 8px;
          text-align: center;
        }
        .ad--fixedBottom {
          text-align: center;
          background: rgba(255, 255, 255, 0.85);
          position: fixed;
          bottom: 0;
          height: 50px;
          width: 100%;
          z-index: 999;
        }
        .feedNavBackdrop {
          background: #fff;
          margin: 0;
        }
        .layout__main {
          margin-top: 20px;
          margin-bottom: 20px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .layout__main {
            min-height: calc(100vh - 177px);
          }
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .layout__main {
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .ad--fixedBottom {
            display: none;
          }
        }
        @media (min-width: 1136px) {
          .layout__main {
            min-height: calc(100vh - 714px);
          }
        }
      `}</style>
    </>
  )
}

export default observer(SiteLayout)
